<template>
  <base-view title="Reglas" icon="clipboard">
    <div>
      <check-authorization :requiresAuthorizations="['indice reglas']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Relación y Descripción de Reglas aplicables a los comprobantes fiscales
            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <search-bar
                placeholder="Buscar por nombre o descripción de regla"
                @filter="(filter) => this.filter = filter"></search-bar>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <se-reglas-list @on-got-item="showForm=true" :filter="filter"></se-reglas-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import SeReglasList from '@/components/SE/Reglas/SeReglasList'
import { mapActions, mapGetters } from 'vuex'
import SearchBar from '../../components/Common/Views/SearchBar.vue'

const STORE_MODULE = 'estructuraCfdiModule'

export default {
  name: 'SeEstructuraCfdiMainView',

  components: {
    SearchBar,
    SeReglasList
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      filter: '',
      tableData: [{
        name: 'Ziuta',
        surname: 'Kozak',
        children: [
          { name: 'Czerwony Kapturek', surname: 'Kozak' }
        ]
      }, {
        name: 'Koziolek',
        surname: 'Matolek',
        children: [
          {
            name: 'Timon',
            surname: 'Matolek',
            children: [
              {
                name: 'Timon Junior', surname: 'Matolek'
              }
            ]
          }
        ]
      }, {
        name: 'Pumba',
        surname: 'unknown'
      }],
      columns: [
        {
          label: 'Name',
          id: 'name'
        },
        {
          label: 'Surname',
          id: 'surname'
        }
      ]
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, ['isEditingResource'])
  },

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>

<style scoped>
.table{
  width: 90%;
  margin: auto;
}
</style>
