<template>
  <div>
    <!-- Table -->
    <b-table
      id="reglasTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getResourceList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="No se encontraron reglas con el criterio de búsqueda escrito"
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <template #cell(nombre)="row">
        <filtered-row :value="row.item.nombre" :filter="filter"/>
      </template>

      <template #cell(descripcion)="row">
        <filtered-row :value="row.item.descripcion" :filter="filter"/>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="inpcModule/getResourceToEdit"
            deletePath="inpcModule/deleteResource"
            :editPermissions="['actualizar inpc']"
            :deletePermissions="['eliminar inpc']"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>
    </b-table>
    <loading v-else></loading>
    <!--div v-if="isThereData">
      <paginator
        aria-controls="usersTable"
        :disabled="!getAvailableList"
        show-path="userModule/getUsers"
        :resource="getResourceList"
      ></paginator>
    </div-->
    <!-- End Table -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilteredRow from '../../Common/Table/FilteredRow.vue'

const STORE_MODULE = 'reglasModule'

export default {
  name: 'SeReglasList',

  components: {
    FilteredRow
  },

  props: {
    filter: {
      required: true
    }
  },

  data () {
    return {
      fields: [
        { key: 'nombre', label: 'Nombre', sortable: true },
        { key: 'descripcion', label: 'Descripcion', sortable: true }
      ],
      filterOn: ['nombre', 'descripcion']
    }
  },

  created () {
    this.submit()
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getAvailableList',
      'isThereData',
      'getResource'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getResource`)

      if (error) this.$notify({ error, message }, 'Reglas')
    },

    getRoleVariant (role) {
      if (role === 'admin') return 'success'

      return 'primary'
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    },

    input (value) {
      console.log(value)
    }
  }
}
</script>

<style scoped>

</style>
